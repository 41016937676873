import tw, { styled } from 'twin.macro'
import { TourProvider } from '@reactour/tour'
import { Modal, Button } from 'react-bootstrap'

export const ModalCardontainer = styled.div`
    ${tw`mt-[80px] py-[15px] px-0 min-h-[50vh]`}
`

export const ModalContent = styled.section`
    ${tw`py-0 px-0 lg:px-5`}

    p {
        ${tw`font-helvetica font-normal text-base lg:text-xl text-gray-custom-900`}
    }

    .highlight-text {
        ${tw`text-sub-dominant`}
    }
`

export const ModalTitle = styled.h2`
    ${tw`font-montserrat font-bold text-lg lg:text-xl text-gray-custom-900 lg:mt-4 lg:mb-6 my-3.5`}

    .sub-title {
        ${tw`text-dominant-dark`}
    }
`

export const ModalText = styled.p`
    ${tw`font-montserrat text-base`}

    width: 100%;
`

export const CodeText = styled.span`
    ${tw`font-montserrat font-bold text-base`}

    color: #512f8b;
`

export const ImgContent = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}
`

export const ModalInsert = styled(Modal)`
    .modal-content {
        ${tw`!rounded-[10px] p-2.5`}

        .modal-header {
            ${tw`border-none`}

            .btn-close {
                ${tw`!opacity-100`}
                background-size: 25px;
            }
        }

        .modal-body {
            ${tw`flex flex-col items-center`}

            img {
                ${tw`m-2.5`}
            }

            h6 {
                ${tw`font-montserrat text-center text-base font-semibold color[var(--header-botton-color)]`}
            }

            p {
                ${tw`my-2.5 mx-0 text-center text-sm font-helvetica font-normal color[var(--header-botton-color)]`}
            }
        }
    }
`

export const ModalButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[var(--background-color)] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: var(--sub-dominant-color);
    }
    background-color: var(--sub-dominant-color);
    border-color: transparent !important;
`

export const ModalButtonBack = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    background-color: #ffffff;
    border-color: #000000 !important;
`

export const ButtonsContainer = styled.div`
    ${tw`flex flex-col-reverse gap-1 items-center p-0`}
    ${tw`lg:flex-row lg:py-0 lg:px-1`}
`
