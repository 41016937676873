import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { MessageContent, TitleContent, FormSelect } from './CommonFields-Styles'
import { Form, Container, Row, Col } from 'react-bootstrap'
import useData from '../../hooks/useData'
import { possessionsService } from '../../../../../../domain/services/User.service'
import { useSelector } from 'react-redux'

interface CommonFieldProps {
    departmentV: string
    cityV: string
    addressV: string
}

const CommonFields: FC<CommonFieldProps> = ({ departmentV, cityV, addressV }) => {
    const { register, control, formState, clearErrors, setError } = useFormContext()
    const { errors } = formState
    const { departaments } = useData()
    const [city, setCity] = useState(null)
    const departamentos = Array.isArray(departaments) ? departaments : []
    const ciudades = Array.isArray(city) ? city : []

    const [selecteddepartment, setSelecteddepartment] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [streetAddress, setStreetAddress] = useState('')
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    useEffect(() => {
        setSelecteddepartment(departmentV)

        getCities(departmentV)
        setSelectedCity(cityV)
        setStreetAddress(addressV)
    }, [departmentV])

    const getCities = (departmentV: string) => {
        const params = {
            idRegion: departmentV,
        }
        possessionsService
            .useGetSecurity(
                `/api/RealEstateExchangeM2/GetCities?idRegion=${params.idRegion}`,
                tokenSave
            )
            .then((response: any) => {
                console.log('respuesta city', response)
                if (response != undefined) {
                    setCity(response.Data)
                }
            })
    }

    return (
        <Container>
            <Form.Group className="mb-3">
                <MessageContent>
                    <Row className="justify-content-md-center">
                        <Col md={12}>
                            <Form.Label>
                                Tenga presente que todos los datos son obligatorios
                            </Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Label>Verifique la información antes de enviarla</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Label>
                                Primero ingrese y guarde la información del inmueble para habilitar
                                el cargue de fotos.
                            </Form.Label>
                        </Col>
                    </Row>
                </MessageContent>
            </Form.Group>
            <Form.Group className="mb-3">
                <Row className="justify-content-md-center">
                    <Col md={12}>
                        <TitleContent>Datos Inmueble</TitleContent>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md={2}>
                        <Form.Label>Departamento</Form.Label>
                    </Col>
                    <Col md={6}>
                        <Controller
                            control={control}
                            name={'department'}
                            rules={{ required: true }}
                            render={({ field: { onChange, value, onBlur } }) => (
                                <FormSelect>
                                    <Form.Select
                                        className="selectFromControl"
                                        {...register('department', { required: true })}
                                        value={selecteddepartment}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                clearErrors('department')
                                            }
                                            setSelecteddepartment(e.target.value)
                                            setSelectedCity('')
                                            getCities(e.target.value)
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('department', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('department')
                                            }
                                            onBlur()
                                        }}
                                        isInvalid={!!errors['department']}
                                    >
                                        <option value="">Selecciona...</option>
                                        {departamentos.map((departamento: any, index) => (
                                            <option key={departamento.Id} value={departamento.Id}>
                                                {departamento.Name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FormSelect>
                            )}
                        />
                        {errors['department'] && (
                            <Form.Control.Feedback type="invalid">
                                {errors['department']?.message}
                            </Form.Control.Feedback>
                        )}
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className="mb-3">
                <Row className="justify-content-md-center">
                    <Col md={2}>
                        <Form.Label>Ciudad</Form.Label>
                    </Col>
                    <Col md={6}>
                        <Controller
                            control={control}
                            name={'city'}
                            rules={{ required: true }}
                            render={({ field: { onChange, value, onBlur } }) => (
                                <FormSelect>
                                    <Form.Select
                                        className="selectFromControl"
                                        {...register('city', { required: true })}
                                        value={selectedCity}
                                        onChange={(e) => {
                                            console.log('c', e.target.value)
                                            if (e.target.value !== '') {
                                                clearErrors('city')
                                            }
                                            setSelectedCity(e.target.value)
                                            onChange(e)
                                        }}
                                        onBlur={(e) => {
                                            console.log('c', e.target.value)
                                            if (e.target.value === '' || e.target.value === null) {
                                                setError('city', {
                                                    message: 'Este campo es obligatorio',
                                                })
                                            } else {
                                                clearErrors('city')
                                            }
                                        }}
                                        isInvalid={!!errors['city']}
                                    >
                                        <option value="">Selecciona...</option>
                                        {ciudades.map((ciudad: any, index) => (
                                            <option key={ciudad.Id} value={ciudad.Id}>
                                                {ciudad.Name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FormSelect>
                            )}
                        />
                        {errors['city'] && (
                            <Form.Control.Feedback type="invalid">
                                {errors['city']?.message}
                            </Form.Control.Feedback>
                        )}
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group className="mb-3">
                <Row className="justify-content-md-center">
                    <Col md={2}>
                        <Form.Label>Dirección</Form.Label>
                    </Col>
                    <Col md={6}>
                        <Controller
                            control={control}
                            name={'address'}
                            rules={{ required: true }}
                            render={({ field: { onChange, value, onBlur } }) => (
                                <Form.Control
                                    type="text"
                                    {...register('address', { required: true })}
                                    value={streetAddress}
                                    onChange={(e) => {
                                        if (e.target.value !== '') {
                                            clearErrors('address')
                                        }
                                        setStreetAddress(e.target.value)
                                        onChange(e)
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value === '' || e.target.value === null) {
                                            setError('address', {
                                                message: 'Este campo es obligatorio',
                                            })
                                        } else {
                                            clearErrors('address')
                                        }
                                        onBlur()
                                    }}
                                    isInvalid={!!errors['address']}
                                />
                            )}
                        />
                        {errors['address'] && (
                            <Form.Control.Feedback type="invalid">
                                {errors['address']?.message}
                            </Form.Control.Feedback>
                        )}
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group className="mb-3">
                <MessageContent>
                    <Row className="justify-content-md-center">
                        <Col md={12}>
                            <Form.Label>
                                Recuerde incluir su dirección de acuerdo a la última nomenclatura de
                                su inmueble, Por su seguridad la dirección no sera publicada. Sólo
                                se utiliza para determinar el barrio y el sector en el que esta
                                localizado su inmueble. El número de apartamento no es parte de la
                                dirección.
                            </Form.Label>
                        </Col>
                    </Row>
                </MessageContent>
            </Form.Group>
        </Container>
    )
}
export default CommonFields
