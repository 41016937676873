import tw, { styled } from 'twin.macro'
import { Form } from 'react-bootstrap'
import {
    FormLabel as FormLabelApp,
    FormGroup as FormGroupApp,
    InputGroup as InputGroupApp,
    CheckInput,
} from '../../../../components/includes'
import InputGroupBs from 'react-bootstrap/InputGroup'

export const RowTableTitle = styled.tr`
    background-color: var(--dominant-color-dark);
    ${tw`font-montserrat text-white text-center `}

    .realEstateOffer {
        width: 15% !important;
    }

    .realEstateType {
        width: 20% !important;
    }
`

export const RowTable = styled.tr`
    background-color: var(--dominant-color);
    ${tw`font-montserrat text-center text-base text-gray-400`}
`
export const InputGroupCheck = styled(InputGroupApp)`
    ${tw`flex items-center justify-start mb-4 text-center`}
`

export const FormCheck = styled(Form.Check)`
    ${tw`flex items-center justify-start mb-4 text-center`}

    .form-check-input {
        ${tw`!w-6 !h-6 mr-3 cursor-pointer`}
    }

    .form-check-label {
        ${tw`!p-0 mt-1 cursor-pointer`}
    }
`

export const InputGroupText = styled.input`
    ${tw`font-montserrat text-center text-base text-black`}
    border: 2px solid var(--dominant-color-dark);
    outline: none;
    padding: 5px;
    margin: 5px;
    width: 100%;
    color: #333; /* Color de texto opcional */
    border-radius: 0.375rem; /* Añade un borde redondeado */
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05); /* Agrega una sombra suave */
`
export const IconUpdate = styled.div`
    ${tw`font-montserrat text-center`}

    .icono-boton {
        background: transparent;
        border: none;
        cursor: pointer;
    }
`

export const InputGroup = styled(InputGroupBs)``

export const InputSelectGroup = styled(InputGroup)`
    ${tw`font-helvetica font-normal text-base text-[#425453] pl-10 border-none cursor-pointer`}
    border: 2px solid var(--dominant-color-dark);
    outline: none;
    padding: 17px;
    margin: 1px;
    width: 100%;
    color: #333; /* Color de texto opcional */
    border-radius: 0.375rem; /* Añade un borde redondeado */
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05); /* Agrega una sombra suave */
`

export const FormSelect = styled(Form.Select)`
    ${tw`font-helvetica font-normal text-base text-[#425453] pl-10 border-none cursor-pointer`}
    ${tw`!absolute z-0 !left-0 !w-full !h-full bottom-0`}
`

export const FormOption = styled.option`
    ${tw`text-base cursor-pointer h-12.5`}
`
export const TableContainer = styled.table`
    ${tw`w-full h-16`}
`
export const THead = styled.thead`
    ${tw`border-b border-solid border-[#00000029] w-full`}

    & tr {
        ${tw`h-16`}

        & th {
            & h4 {
                ${tw`m-0 font-helvetica font-normal text-base text-gray-custom-500`}
            }
        }
    }
`
export const TBody = styled.tbody`
    ${tw`w-full`}

    & tr {
        ${tw`h-[52px] border-b border-solid border-[#00000029]`}

        & td {
            &:first-child {
                & p {
                    ${tw`font-normal font-helvetica py-1`}
                }
                & span {
                    ${tw`font-bold font-montserrat py-1`}
                }
            }
            & p {
                ${tw`m-0 font-montserrat font-bold text-base text-gray-custom-500`}
            }
        }
    }
`
