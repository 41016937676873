import { useForm, FieldValues, DeepPartial, Resolver } from 'react-hook-form'
import * as yup from 'yup'

export const possessionsFormSchema = yup
    .object({
        tipoInmueble: yup.string().required('Campo obligatorio'),
        accion: yup.string().required('Campo obligatorio'),
        configuration: yup.object(),
    })
    .required()

export type PossessionsFormType = yup.InferType<typeof possessionsFormSchema>

interface IUsePossessionsFormArgs<F extends FieldValues = PossessionsFormType> {
    defaultValues: DeepPartial<F | PossessionsFormType>
    validationSchema?: Resolver<F | PossessionsFormType>
}

export const usePossessionsForm = <TFieldValues extends FieldValues = PossessionsFormType>({
    defaultValues,
    validationSchema,
}: IUsePossessionsFormArgs<TFieldValues>) => {
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        control,
        formState: { errors },
    } = useForm<TFieldValues | PossessionsFormType>({
        mode: 'all',
        resolver: validationSchema,
        defaultValues,
    })

    return {
        register,
        handleSubmit,
        watch,
        getValues,
        control,
        errors,
    }
}
