import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { LayoutContent } from '../../transverse'
import { BreadcrumbApp } from '../../components'

import { ContainerPossessions } from './PossessionsInsert-styles'
import PossessionsForm from './inc/PossessionsForm/PossessionsForm'
import { useFeatureEnableFunctions } from '../FeatureEnableFunctions/FeatureEnableFunctions'
import { parseStringToBoolean } from '../../utils/misc'

const PossessionsInsert = (): JSX.Element => {
    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const [buildingName, setBuildingName] = useState('')
    const [actionType, setActiongType] = useState('')

    const handleBuildingName = (typeName: string) => {
        setBuildingName(typeName)
    }

    const handleActiongType = (typeName: string) => {
        setActiongType(typeName)
    }

    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Inserción de inmueble',
            active: false,
            onClick: () => {
                window.location.reload()
            },
        },
        {
            text: buildingName,
            active: false,
            onClick: () => {
                console.log('buildingName', buildingName)
            },
        },
        {
            text: actionType,
            active: false,
            onClick: () => {
                console.log('actionType', actionType)
            },
        },
    ]

    /* Manejo para apagado de funcionalidades */
    // consulta estado de la funcionalidad en el archivo .env
    const enableM2PublishRealState = process.env.REACT_APP_M2_PUBLISH_REAL_STATE_ENABLE as
        | 'false'
        | 'true'
    // Consultar estado de la funcionalidad si es true o false en DB
    const m2PublishRealState = useFeatureEnableFunctions('OnOffFunctionM2PublishRealState')

    // En este useEffect se utiliza para validar, si alguno de los dos parámetros está en false, en caso de que se culpa con esta condición se deberá redirigir al usuario a la página not-found, este caso solo aplica cuando se apaga un componente por completo en este caso Insertar Inmueble
    useEffect(() => {
        if (!m2PublishRealState || !parseStringToBoolean(enableM2PublishRealState)) {
            history.push('/not-found')
        }
    }, [history, m2PublishRealState, enableM2PublishRealState])

    return (
        <LayoutContent>
            <ContainerPossessions>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />
                {/* <TitleModule /> */}
                <PossessionsForm
                    handleBuildingName={handleBuildingName}
                    handleActiongType={handleActiongType}
                ></PossessionsForm>
            </ContainerPossessions>
        </LayoutContent>
    )
}

export default PossessionsInsert
