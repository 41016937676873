import { useEffect, useState } from 'react'
import { possessionsService } from '../../../../../domain/services/User.service'
import { useSelector } from 'react-redux'

const useData = () => {
    const [typeProperty, setTypeProperty] = useState(null)
    const [actions, setActions] = useState(null)
    const [departaments, setDepartaments] = useState(null)
    const state = useSelector((stateRef: any) => stateRef)

    const tokenSave = state.auth.token

    useEffect(() => {
        possessionsService
            .useGetSecurity('api/RealEstateExchangeM2/GetRealEstateType', tokenSave)
            .then((response: any) => {
                if (response != undefined) {
                    setTypeProperty(response)
                }
            })

        possessionsService
            .useGetSecurity('api/RealEstateExchangeM2/GetBusinessType', tokenSave)
            .then((response: any) => {
                if (response != undefined) {
                    setActions(response)
                }
            })

        possessionsService
            .useGetSecurity('api/RealEstateExchangeM2/GetRequestRegions', tokenSave)
            .then((response: any) => {
                if (response !== undefined) {
                    setDepartaments(response.Data)
                }
            })
    }, [])

    return { typeProperty, actions, departaments }
}

export default useData
