// services

// models & interfaces
import { featureEnableFunctionsServices } from '../../../domain/services/featureEnableFunctions'
import { AppDispatch } from '../store/store'
import { FEATURE_ENABLE_FUNCTIONS_GET } from './featureEnableFunctions.types'

export const getFeatureEnableFunctionsAction = () => (dispatch: AppDispatch) => {
    dispatch(
        featureEnableFunctionsServices.getFeatureEnableFunctions([
            FEATURE_ENABLE_FUNCTIONS_GET.REQUEST,
            FEATURE_ENABLE_FUNCTIONS_GET.SUCCESS,
            FEATURE_ENABLE_FUNCTIONS_GET.FAILURE,
        ])()
    )
}
