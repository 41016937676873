import React, { useState } from 'react'
import {
    TermsAndCondContainer,
    Header,
    AceptButton,
    Footer,
    CheckTermsCond,
    ContainerCheck,
    TermText,
    CancelButton,
} from './TermnsAndConditions-Styles'
import TitleModule from './inc/TitleModule/index'
import { useHistory } from 'react-router-dom'

interface TermsAndConditionsProps {
    onAccept: () => void
    onReject: () => void
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ onAccept, onReject }) => {
    const history = useHistory()
    const [checked, setChecked] = useState(false)

    const handleCheckboxChange = () => {
        setChecked(!checked)
    }

    const redirections = (url: string): void => {
        history.push(url)
    }

    const isButtonEnabled = checked // El botón estará habilitado si el checkbox está marcado

    return (
        <>
            <TitleModule />
            <TermsAndCondContainer>
                <Header>
                    <h3>Consideraciones previas</h3>
                </Header>
                <p style={{ textAlign: 'justify' }}>
                    CONSIDERACIONES PREVIAS Nota 1. Por su seguridad y para que la consignación de
                    promoción de su propiedad en La Bolsa Virtual Inmobiliaria de Cavipetrol cumpla
                    con lo establecido legalmente, es fundamental que tenga conocimiento y claridad
                    que este es un medio que promociona a través de internet y por ende lo pueden
                    consultar tanto asociados como personas externas a Cavipetrol. Nota 2. Usted
                    autoriza irrevocablemente a CAVIPETROL a enviar sus datos personales y los de su
                    propiedad a un posible cliente, con el fin de que se comercialice su propiedad
                    objeto del presente. Nota 3. Nada de lo incluido en Web Site por CAVIPETROL o
                    por los asociados constituye recomendación, asesoría o consejo suministrado por
                    CAVIPETROL. El uso del Web Site y del material, al igual que las decisiones que
                    usted adopte con base en este Web Site y el Material, se hacen bajo su propio y
                    exclusivo riesgo. CAVIPETROL. Recomienda que todas las decisiones que usted
                    pretenda adoptar con base en el Material y cualquier otra información incluida
                    en el Web Site sean consultadas con sus propios asesores y consultores.
                    CAVIPETROL no será responsable por cualquier decisión que usted tome con base en
                    el uso de este Web Site. POLÍTICAS Y TÉRMINOS DEL PREACUERDO CLÁUSULA PRIMERA
                    (1): Bajo la gravedad de juramento usted acepta y reconoce las consideraciones
                    expuestas, anteriormente, y da fe de que los datos consignados en este portal
                    por usted son verídicos y pueden ser comprobados en cualquier momento. Asimismo
                    que usted tiene la posesión real y legal sobre el bien relacionado. CLÁUSULA
                    SEGUNDA (2): CAVIPETROL no otorga garantía alguna, expresa o implícita, acerca
                    de la veracidad, exactitud o confiabilidad de la información incluida en este
                    Web Site por los asociados ni apoya o respalda las opiniones expresadas por los
                    asociados. Usted reconoce y declara que la confianza por usted depositada en
                    cualquier material incluido en el Web Site por los usuarios se hará bajo su
                    propio riesgo. CLÁUSULA TERCERA (3) CAVIPETROL. Actúa como un medio pasivo para
                    la distribución y publicación en Internet de la información presentada por los
                    asociados y no tiene obligación de revisar anticipadamente tal información ni es
                    responsable de revisar o monitorear la información incluida en este Web Site por
                    los asociados. Si CAVIPETROL es notificada por un asociado acerca de la
                    existencia de información que no cumpla con estos Términos y Condiciones,
                    CAVIPETROL, puede investigar tal información y determinar de buena fe y a su
                    exclusiva discreción si remueve o elimina tal información o solicita que sea
                    removida o eliminada del Web Site. Se reserva el derecho de expulsar usuarios o
                    de prohibir su acceso futuro al Web Site por violación de estos Términos y
                    Condiciones o de la ley aplicable. Igualmente, CAVIPETROL se reserva el derecho
                    de eliminar del Web Site información presentada o incluida por un asociado,
                    cuando lo considere apropiado o necesario a su exclusiva discreción, si estima o
                    cree que tal información puede generar responsabilidad para CAVIPETROL, o puede
                    causar la pérdida de los servicios de sus proveedores de Internet (ISPs) o de
                    otros proveedores. CLÁUSULA CUARTA (4) El cumplimiento de los términos y
                    condiciones de los contratos o acuerdos que usted llegue a celebrar con otros
                    usuarios de CAVIPETROL, así como el cumplimiento de las leyes aplicables a tales
                    contratos o acuerdos, es responsabilidad exclusiva de los asociados de
                    CAVIPETROL. Debido a que la autenticidad de los asociados de Internet es
                    difícil, CAVIPETROL puede confirmar que cada asociado es quien dice ser. Por tal
                    razón recordamos que usted (el asociado) no debe suministrarle el nombre y
                    usuario ni el pasword a otra persona ya que esto es información personal e
                    intransferible. Debido a que CAVIPETROL no se involucra directamente en las
                    relaciones o tratos entre sus asociados ni controla el comportamiento de los
                    asociado o participantes en CAVIPETROL, en el evento en que usted tenga una
                    disputa con uno o más asociados de CAVIPETROL, usted libera a CAVIPETROL de
                    cualquier reclamación, demanda o daño de cualquier naturaleza, o que surja de
                    cualquier otra forma se relacione con dicha disputa. CLÁUSULA QUINTA (5)
                    CAVIPETROL puede controlar la información suministrada por otros asociados y que
                    pueda estar disponible a través de este Web Site. Por su propia naturaleza la
                    información proveniente de terceras personas puede ser ofensiva, dañina o
                    inexacta y en algunos casos puede ser titulada o rotulada de manera errónea o
                    decepcionante. CAVIPETROL. Espera que usted emplee la debida precaución y
                    sentido común cuando use este Web Site. CLÁUSULA SEXTA (6) La información
                    publicada puede contener inexactitudes o errores tipográficos. CAVIPETROL no
                    otorga garantía alguna, expresa o implícita, acerca de la precisión, exactitud,
                    confiabilidad u oportunidad del Web Site o de la información contenida allí.
                    Nada de lo incluido en el Web Site por CAVIPETROL por sus proveedores o por los
                    afilados constituye recomendación, asesoría o consejo suministrado por
                    CAVIPETROL. El uso del Web Site y de la información allí consignada
                    promocionalmente, al igual que las decisiones que usted adopte con base en este
                    Web Site, se hace bajo su propio y exclusivo riesgo. CLAUSULA SÉPTIMA (7)
                    CAVIPETROL. Recomienda que todas las decisiones que usted pretenda adoptar con
                    base en la información incluida en el Web Site sean consultadas con sus propios
                    asesores y consultores. CAVIPETROL no será responsable por cualquier decisión
                    que usted tome con base en el uso de este Web Site. Los usuarios reconocen y
                    aceptan que son los únicos responsables por la información contenida en este web
                    site y en las oportunidades de negocios o transacciones que sean incluidas o
                    publicadas por ellos en este Web Site. CLÁUSULA OCTAVA (8) MANEJO DE INFORMACIÓN
                    LEY 1581 DEL AÑO 2012: El Contratista se obliga a guardar secreto de todos los
                    datos personales que conozca y a los que tenga acceso en virtud del presente
                    contrato. Igualmente, custodiará e impedirá el acceso a los datos personales a
                    cualquier usuario no autorizado o persona ajena a su organización. Las
                    anteriores obligaciones se extienden en cualquier fase del tratamiento que de
                    esos datos pudiera realizarse y subsistirán aún después de terminados los
                    mismos. El Contratista únicamente tratará los datos conforme a las instrucciones
                    que reciba expresamente de CAVIPETROL, y no los destinará, aplicará o utilizará
                    con fin distinto al que figure en el presente contrato. Así mismo, se compromete
                    a no revelar, transferir, ceder o de otra forma comunicar las bases de datos o
                    datos contenidos en ellos, ya sea verbalmente o por escrito, por medios
                    electrónicos, papel o mediante acceso informático, ni siquiera para su
                    conservación, a otras personas; salvo que previa indicación expresa del
                    responsable del base de datos, comunique los datos a un tercero designado por
                    aquél, al que hubiera encomendado la prestación de un servicio. El Contratista
                    manifiesta estar al corriente en lo que concierne a las obligaciones derivadas
                    de la normativa de protección de datos. Asimismo, garantiza el mantenimiento de
                    las medidas de seguridad, así como cualesquiera otras que le fueren impuestas,
                    de índole técnica y organizativa, necesarias para garantizar la seguridad de los
                    datos de carácter personal. Finalizada la prestación del servicio contratado,
                    los datos personales serán destruidos o devueltos a CAVIPETROL, al igual que
                    cualquier soporte o documentos en que conste algún dato personal objeto del
                    tratamiento. No procederá la destrucción de los datos cuando exista una
                    previsión legal que exija su conservación, en cuyo caso deberá procederse a la
                    devolución de los mismos garantizando a CAVIPETROL conservación. El Contratista
                    podrá conservar, debidamente bloqueados, los datos en tanto pudieran derivarse
                    responsabilidades de su relación con CAVIPETROL. En cualquier caso, el
                    contratista comunicará a CAVIPETROL cualquier incidencia que se produzca en
                    ejecución del presente contrato, que pueda afectar a la confidencialidad,
                    integridad y disponibilidad de los datos personales, dentro del plazo de dos
                    días a contar desde la fecha en que se hubiese producido la incidencia o hubiese
                    tenido conocimiento de la misma, para que se adopten las medidas correctoras
                    oportunas. Del mismo modo, el Contratista pondrá en conocimiento del personal a
                    su servicio las obligaciones indicadas en la presente cláusula, cerciorándose,
                    mediante la adopción de las medidas pertinentes, de que se acata su contenido.
                    El Contratista no podrá subcontratar con un tercero la realización de
                    tratamientos de datos que le hubiera encomendado CAVIPETROL, salvo que hubiera
                    obtenido de ésta autorización expresa para ello; la cual, de existir se anexará
                    al presente contrato.
                </p>
            </TermsAndCondContainer>
            <br />
            <ContainerCheck>
                <CheckTermsCond type="checkbox" checked={checked} onChange={handleCheckboxChange} />
                <TermText>
                    He leído y acepto a Cavipetrol a consultar y reportar en las centrales de
                    información financiera
                </TermText>
            </ContainerCheck>
            <Footer>
                <AceptButton
                    type="button"
                    disabled={!isButtonEnabled}
                    onClick={() => {
                        onAccept()
                        history.push('/possessions-insert')
                    }}
                >
                    Aceptar
                </AceptButton>

                <CancelButton type="button" variant="primary" onClick={() => redirections('/')}>
                    No aceptar
                </CancelButton>
            </Footer>
        </>
    )
}

export default TermsAndConditions
