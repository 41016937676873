import React from 'react'
import { RegisterButton, ButtonContent } from './ButtonRegister-Styles'
import { Button } from '../../../../components'

interface ButtonRegisterProps {
    onClick?: () => void
    isLoading?: boolean
}

const ButtonRegister: React.FC<ButtonRegisterProps> = ({ onClick, isLoading }) => {
    return (
        <>
            <ButtonContent>
                <Button
                    id="btnSubmit"
                    variant="sub-dominant"
                    type="button"
                    onClick={onClick}
                    isLoading={isLoading}
                    extend
                    disabled={isLoading}
                >
                    Enviar
                </Button>
            </ButtonContent>
        </>
    )
}

export default ButtonRegister
