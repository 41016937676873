import React, { FC, useEffect, useState, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import useDynamicForm from '../../hooks/useDynamicForm'
import { Container, Row, Col, Pagination, PageItem } from 'react-bootstrap'
import {
    Wrapper,
    Content,
    TitleContent,
    TextForm,
    PaginationContent,
    ButtonPagination,
} from '../CommonFields/CommonFields-Styles'
import FieldForm, { formatFieldName } from '../FieldForm/FieldForm'
import CommonFields from '../CommonFields/CommonFields'
import ButtonRegister from '../ButtonRegister/ButtonRegister'
import ButtonCancel from '../ButtonCancel/ButtonCancel'
import { setCommonValues, useInsertData } from '../../hooks/useInsertData'
import Title from '../TitleFromModule'
import ImagePossessionsForm from '../ImagePossessionsFrom/ImagePossesionsForm'
import InsertModal from '../InsertModal/InsertModal'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import {
    ImagePreview,
    ImageUploadPrompt,
    ImageUploadContainer,
    ImageCounter,
    DeleteButton,
    ImageContainer,
    ImageContainerWrapper,
} from './BuildingForm-Styles'
import { ContainerTitle, TitleStep } from '../TitleFromModule/titleFromModule-styles'

interface BuildingFormProps {
    tipoInmueble: string
    accion: string
    configuration: any
}

const BuildingForm: FC<BuildingFormProps> = ({ tipoInmueble, accion, configuration }) => {
    const methods = useForm()
    const { handleSubmit, getValues, reset, trigger } = methods
    const { requiredFields, optionalFields, currentPage, setCurrentPage, totalPages } =
        useDynamicForm(configuration)
    const [showModal, setShowModal] = useState(false)
    const [textTitleModal, setTextTitleModal] = useState('')
    const [textBodyModal, setTextBodyModal] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [statusRes, setStatusRes] = useState()
    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }

    const handleClose = () => {
        setShowModal(false)
        if (statusRes === 200) {
            redirection('/home-wallet')
        }
    }

    let data: any = {}

    const HandleFormSubmit = () => {
        let hasImages = true
        if (currentPage === totalPages && (images.length < 3 || images.length > 20)) {
            setShowModal(true)
            setTextTitleModal('¡Ha ocurrido un error!')
            setTextBodyModal('Por favor, carga al menos 3 y no más de 20 imágenes.')
            hasImages = false
        }

        if (hasImages) {
            const formData = getValues()
            const lengthData = Object.keys(pageData).length
            setIsLoading(true)

            if (lengthData === totalPages) {
                saveSubmitData(lengthData)
            } else {
                saveSubmitData(lengthData)
                // Al finalizar de recorrer "pageData" agregar la última página del form
                data = { ...data, ...formData }
            }
            const dataToSend = setCommonValues(data, tipoInmueble, accion)
            const amenities: any = {}

            for (const key in data) {
                if (
                    key.toString() !== 'price' &&
                    key.toString() !== 'city' &&
                    key.toString() !== 'administration' &&
                    key.toString() !== 'stratum' &&
                    key.toString() !== 'video' &&
                    key.toString() !== 'address' &&
                    key.toString() !== 'neighborhood' &&
                    key.toString() !== 'latitude' &&
                    key.toString() !== 'longitude' &&
                    data[key] !== undefined &&
                    data[key] !== '' &&
                    data[key] !== 'false'
                ) {
                    amenities[key] = data[key]
                }
            }
            dataToSend.Amenities = amenities

            const formDataToSend = new FormData()
            formDataToSend.append('body', JSON.stringify(dataToSend))

            SaveBuilding(dataToSend)
        }
    }

    const SaveBuilding = (dataToSend: any) => {
        useInsertData(dataToSend, tokenSave, images)
            .then((res: any) => {
                if (res && res['status'] === 200) {
                    setShowModal(true)
                    setTextTitleModal('¡Registro completado!')
                    setTextBodyModal('El inmueble ha sido registrado correctamente')
                    setShowModal(true)
                } else {
                    setShowModal(true)
                    setTextTitleModal('¡Ha ocurrido un error!')
                    setTextBodyModal(
                        'El inmueble no ha sido registrado, por favor intente de nuevo'
                    )
                }
                setIsLoading(false)
                setStatusRes(res ? res['status'] : undefined)
            })
            .catch((error) => {
                console.error(error)
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal('El inmueble no ha sido registrado, por favor intente de nuevo')
                setIsLoading(false)
            })
    }

    const saveSubmitData = (lengthData: number) => {
        for (let i = 0; i < lengthData; i++) {
            Object.entries(pageData).map((field) => {
                data = { ...data, ...field[i + 1] }
            })
        }
    }

    /**Implemento la paginación**/

    const [pageData, setPageData] = useState<{ [key: number]: any }>({})
    const [departmentV, setDepartmentValue] = useState('')
    const [cityV, setCityValue] = useState('')
    const [addressV, setAddressValue] = useState('')

    const saveCurrentPageData = useCallback(() => {
        const currentFormData = getValues()
        setPageData((prevState) => ({
            ...prevState,
            [currentPage]: currentFormData,
        }))

        if (currentPage === 1) {
            for (const key in currentFormData) {
                if (key.toString() === 'department') {
                    const tempV = currentFormData[key]
                    setDepartmentValue(tempV)
                } else if (key.toString() === 'city') {
                    const tempV = currentFormData[key]
                    setCityValue(tempV)
                } else if (key.toString() === 'address') {
                    const tempV = currentFormData[key]
                    setAddressValue(tempV)
                }
            }
        }
    }, [getValues, currentPage])

    useEffect(() => {
        if (pageData[currentPage]) {
            reset(pageData[currentPage])
        }
    }, [currentPage, reset, pageData])

    const handleNextPage = async () => {
        const result = await trigger()
        if (result) {
            const currentFormData = getValues()

            if (!currentFormData.video || currentFormData.video.trim() === '') {
                currentFormData.video = ''
            } else {
                // Validación de la URL de YouTube
                const youtubeUrlPattern =
                    /^((?:https:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(watch\?v=)?[a-zA-Z0-9_-]{11})$/
                if (currentPage === 1 && !youtubeUrlPattern.test(currentFormData.video)) {
                    setShowModal(true)
                    setTextTitleModal('¡URL de video no válida!')
                    setTextBodyModal(
                        'Por favor, ingrese una URL de YouTube válida o deje el campo vacío.'
                    )
                    return
                }
            }

            // Actualiza la página de datos con los nuevos valores
            setPageData((prevState) => ({
                ...prevState,
                [currentPage]: currentFormData,
            }))

            // Si la validación fue exitosa...
            saveCurrentPageData()
            setCurrentPage(currentPage + 1)
            reset(pageData[currentPage + 1] || {})
        }
    }

    /**Fin**/

    const [images, setImages] = useState<File[]>([])

    const imageDropzone = useDropzone({
        accept: 'image/*' as any,
        onDrop: (acceptedFiles) => {
            // Suma las imágenes aceptadas a las ya existentes
            const newImages = [...images, ...acceptedFiles]

            if (newImages.length > 20) {
                setShowModal(true)
                setTextTitleModal('¡Ha ocurrido un error!')
                setTextBodyModal(
                    'Has alcanzado el límite de 20 imágenes. No puedes seleccionar más.'
                )
                return
            }

            // Si la cantidad está dentro del rango correcto, actualiza el estado
            setImages(newImages)
        },
    })
    const handleDeleteImage = (index: number, e: React.MouseEvent) => {
        e.stopPropagation()
        const updatedImages = images.filter((_, i) => i !== index)
        setImages(updatedImages)
    }

    return (
        <Wrapper className="prueba">
            <FormProvider {...methods}>
                <Content>
                    <ImagePossessionsForm />
                    <Container>
                        {currentPage === 1 && (
                            <>
                                <Title />
                                <CommonFields
                                    departmentV={departmentV}
                                    cityV={cityV}
                                    addressV={addressV}
                                />
                            </>
                        )}
                        <Row className="justify-content-md-center">
                            <Col md={12}>
                                <ContainerTitle>
                                    <TitleStep>
                                        <span>
                                            Paso {currentPage} de {totalPages}
                                        </span>
                                    </TitleStep>
                                </ContainerTitle>
                            </Col>
                            <Col md={12}>
                                <TitleContent>Detalles</TitleContent>
                            </Col>
                            {requiredFields.map((field, index) => {
                                const formattedName = formatFieldName(field.name)
                                return (
                                    <React.Fragment key={index}>
                                        <Col md={2}>
                                            <TextForm>{formattedName}</TextForm>
                                        </Col>
                                        <Col md={4}>
                                            <FieldForm field={field} />
                                        </Col>
                                    </React.Fragment>
                                )
                            })}

                            {currentPage === totalPages && (
                                <>
                                    <Col md={12}>
                                        <TitleContent>Carga tus imágenes</TitleContent>
                                    </Col>
                                    <ImageUploadContainer {...imageDropzone.getRootProps()}>
                                        <input {...imageDropzone.getInputProps()} />
                                        <ImageUploadPrompt>
                                            Arrastra aquí mínimo 3 y máximo 20 imágenes o haz clic
                                            para seleccionar archivos
                                        </ImageUploadPrompt>
                                        <ImageContainerWrapper>
                                            {images.map((image, index) => (
                                                <ImageContainer key={index} className="group">
                                                    <DeleteButton
                                                        onClick={(e) => handleDeleteImage(index, e)}
                                                    >
                                                        X
                                                    </DeleteButton>

                                                    <ImagePreview
                                                        src={URL.createObjectURL(image)}
                                                        alt={`Preview ${index}`}
                                                    />
                                                </ImageContainer>
                                            ))}
                                            <ImageCounter>
                                                <strong className="font-bold">{`${images.length} `}</strong>
                                                imágenes cargadas
                                            </ImageCounter>
                                        </ImageContainerWrapper>
                                    </ImageUploadContainer>
                                </>
                            )}
                        </Row>
                        <Row className="justify-content-md-center">
                            {optionalFields.map((field, index) => {
                                const formattedName = formatFieldName(field.name)
                                return (
                                    <React.Fragment key={index}>
                                        <Col md={2}>
                                            <TextForm>{formattedName}</TextForm>
                                        </Col>
                                        <Col md={4}>
                                            <FieldForm field={field} />
                                        </Col>
                                    </React.Fragment>
                                )
                            })}
                        </Row>
                        <PaginationContent>
                            <Pagination>
                                <ButtonPagination>
                                    {currentPage !== 1 && (
                                        <PageItem
                                            disabled={currentPage === 1}
                                            onClick={() => {
                                                saveCurrentPageData()
                                                setCurrentPage(currentPage - 1)
                                                reset(pageData[currentPage - 1] || {})
                                            }}
                                        >
                                            {'<'} Volver
                                        </PageItem>
                                    )}
                                </ButtonPagination>
                                {currentPage > 1 && currentPage < totalPages && (
                                    <p className="symbol"> {'/'} </p>
                                )}
                                <ButtonPagination>
                                    <PageItem
                                        className="disableFm"
                                        disabled={currentPage === totalPages}
                                        onClick={handleNextPage}
                                    >
                                        Continuar {'>'}
                                    </PageItem>
                                </ButtonPagination>
                            </Pagination>
                        </PaginationContent>
                        {currentPage === totalPages && (
                            <FormProvider {...methods}>
                                <form onSubmit={handleSubmit(HandleFormSubmit)}>
                                    {/* ... */}
                                    <Row>
                                        <Col md={4}>
                                            <ButtonRegister
                                                onClick={handleSubmit(HandleFormSubmit)}
                                                isLoading={isLoading}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ButtonCancel />
                                        </Col>
                                    </Row>
                                </form>
                            </FormProvider>
                        )}
                    </Container>
                </Content>
            </FormProvider>
            <InsertModal
                showModal={showModal}
                textTitle={textTitleModal}
                textBody={textBodyModal}
                handleClose={handleClose}
            />
        </Wrapper>
    )
}

export default BuildingForm
