import Table from 'react-bootstrap/Table'
import { SpaceStep } from '../../PossessionsConsult-styles'
import {
    FormCheck,
    FormOption,
    FormSelect,
    IconUpdate,
    InputGroupCheck,
    InputGroupText,
    RowTableTitle,
    InputSelectGroup,
    TBody,
    TableContainer,
    THead,
} from './possessionsTable-stylles'
import { useEffect, useMemo, useState } from 'react'
import React from 'react'
import useFilter from '../../hooks/useFilter'
import { UpdatePNG, PostPNG, UnPublishPNG } from '../../../../utils/getIcons'
import { LoadingContainer } from '../../../CreditDetail/creditDetail-styles'
import { any } from 'ramda'

interface PossessionsTableProps {
    listProperty: any // Cambia 'any[]' por el tipo adecuado para tus datos
    typeProperty: any
    actions: any
    estado: any
}
const PossessionsTable = ({
    listProperty,
    typeProperty,
    actions,
    estado,
}: PossessionsTableProps): JSX.Element => {
    const filtros = {
        ResourceId: '',
        RealEstateType: '',
        RealEstateOffer: '',
        Address: '',
    }

    const [filtroListado, setFiltroListado] = useState(filtros)
    useEffect(() => {
        return () => {
            setFiltroListado(filtros)
        }
    }, [estado])
    interface ActionsInterface {
        Data: any[]
    }
    const jsonData = [{ Id: 0, Text: 'Error data' }]

    const typesProper =
        typeProperty && Array.isArray((typeProperty as ActionsInterface).Data)
            ? (typeProperty as ActionsInterface).Data
            : jsonData

    const TBusiness =
        actions && Array.isArray((actions as ActionsInterface).Data)
            ? (actions as ActionsInterface).Data
            : jsonData

    const TListPossession =
        listProperty && Array.isArray((listProperty as ActionsInterface).Data)
            ? (listProperty as ActionsInterface).Data
            : jsonData

    const { tableData } = useFilter(filtroListado, TListPossession)

    const handleClick = () => {
        console.log('Funciona')
    }
    const onchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const property = event.target.name // as keyof TableDataItem
        const value = event.target.value
        setFiltroListado({ ...filtroListado, [property]: value })
    }

    const handleClickRefresh = () => {
        console.log('Funciona')
    }
    console.log('Tipo de Inmueble', listProperty.Data.RealEstateType)
    return (
        <>
            <SpaceStep />
            <label onClick={handleClickRefresh}></label>
            <TableContainer>
                <TBody>
                    <RowTableTitle>
                        <th>Inmueble</th>
                        <th>Des-publicar</th>
                        <th>Actualizar</th>
                        <th>Re-publicar</th>
                        <th className="realEstateType">Tipo Inmueble</th>
                        <th className="realEstateOffer">Negocio</th>
                        <th>Dirección</th>
                        <th>Estado M2</th>
                        <th>Días Pendiente Publicación</th>
                    </RowTableTitle>
                    <tr>
                        <td>
                            <InputGroupText
                                type="text"
                                name="ResourceId"
                                value={filtroListado.ResourceId}
                                onChange={onchange}
                            />
                        </td>
                        <td>
                            <div />
                        </td>
                        <td>
                            <div />
                        </td>
                        <td>
                            <div />
                        </td>
                        <td>
                            <InputSelectGroup>
                                <FormSelect
                                    name="RealEstateType"
                                    value={filtroListado.RealEstateType}
                                    onChange={onchange}
                                    size="lg"
                                >
                                    <FormOption></FormOption>
                                    {typesProper
                                        .filter((option) =>
                                            listProperty.Data.some(
                                                (property: any) =>
                                                    property.RealEstateType === option.Id
                                            )
                                        )
                                        .map((option) => (
                                            <FormOption key={option.Id} value={option.Id}>
                                                {option.Text}
                                            </FormOption>
                                        ))}
                                </FormSelect>
                            </InputSelectGroup>
                        </td>
                        <td>
                            <InputSelectGroup>
                                <FormSelect
                                    name="RealEstateOffer"
                                    value={filtroListado.RealEstateOffer}
                                    onChange={onchange}
                                    size="lg"
                                >
                                    <FormOption></FormOption>
                                    {TBusiness.filter((option) =>
                                        listProperty.Data.some(
                                            (property: any) =>
                                                property.RealEstateOffer === option.Id
                                        )
                                    ).map((option) => (
                                        <FormOption key={option.Id} value={option.Id}>
                                            {option.Text}
                                        </FormOption>
                                    ))}
                                </FormSelect>
                            </InputSelectGroup>
                        </td>
                        <td>
                            <InputGroupText
                                type="text"
                                name="Address"
                                value={filtroListado.Address}
                                onChange={onchange}
                            />
                        </td>
                        <td>
                            <div />
                        </td>
                        <td>
                            <div />
                        </td>
                    </tr>
                    {tableData.map((e: any) => (
                        <tr key={e.Id}>
                            <td>{e.ResourceId}</td>
                            <td>
                                <IconUpdate>
                                    <button className="icono-boton" onClick={handleClick}>
                                        <div>
                                            <img src={UnPublishPNG} />
                                        </div>
                                    </button>
                                </IconUpdate>
                            </td>
                            <td>
                                <IconUpdate>
                                    <button className="icono-boton" onClick={handleClick}>
                                        <div>
                                            <img src={UpdatePNG} />
                                        </div>
                                    </button>
                                </IconUpdate>
                            </td>
                            <td>
                                <IconUpdate>
                                    <button className="icono-boton" onClick={handleClick}>
                                        <div>
                                            <img src={PostPNG} />
                                        </div>
                                    </button>
                                </IconUpdate>
                            </td>
                            <td>{typesProper.find((p) => p.Id === e.RealEstateType).Text}</td>
                            <td>{TBusiness.find((p) => p.Id === e.RealEstateOffer).Text}</td>
                            <td>{e.Address}</td>
                            <td>{e.estadoM2}</td>
                            <td>{e.diasPendiente}</td>
                        </tr>
                    ))}
                </TBody>
            </TableContainer>
        </>
    )
}

export default PossessionsTable
