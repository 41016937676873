import tw, { styled } from 'twin.macro'

export const ImgPossessionsForm = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}

    .image-from {
        width: 1000px;
        max-width: 100%;
        cursor: pointer;
        margin: 30px;
    }
`
