import { Modal } from 'react-bootstrap'

// icons
import { IconModalSVG } from '../../../../utils/getIcons'

// styles
import {
    ButtonsContainer,
    ModalInsert,
    ModalButton,
    ModalTitle,
    ModalText,
    ModalButtonBack,
} from './InsertModal-Styled'

import { useSelector, messagesSelector } from '../../../../../selectors'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

interface CreditDetailModalProps {
    showModal: boolean
    handleClose: () => void
    textTitle?: string
    textBody?: string
}

const InsertModal: React.FC<CreditDetailModalProps> = ({
    showModal,
    handleClose,
    textTitle,
    textBody,
}): JSX.Element => {
    const history = useHistory()

    return (
        <ModalInsert
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <button
                    onClick={handleClose}
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                ></button>
            </Modal.Header>
            <Modal.Body>
                {textTitle && <ModalTitle>{textTitle}</ModalTitle>}
                {textBody && <ModalText>{textBody}</ModalText>}
            </Modal.Body>
        </ModalInsert>
    )
}

export default InsertModal
