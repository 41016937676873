import tw, { styled, css } from 'twin.macro'

export const ApartamentosFormWrapper = styled.section`
    ${tw`flex justify-center items-center py-2`}
`

export const ApartamentosFormContent = styled.div`
    ${tw`p-5 lg:px-10 w-full max-w-[800px] lg:max-w-[1200px] rounded-[10px] shadow-[0px 3px 6px #00000029]`}
`
export const ImageUploadContainer = styled.div`
    ${tw`p-4 border-2 border-dashed rounded-md border-gray-500 flex flex-col items-center space-y-4 bg-gray-100 hover:bg-gray-200 cursor-pointer mb-8`}
`

export const ImageUploadPrompt = styled.p`
    ${tw`text-center text-gray-700`}
`

export const ImagePreview = tw.img`
    w-[100px]
    h-[100px]
    mt-2
    mr-2
    rounded-full
    border-2
`
export const ImageCounter = styled.div`
    ${tw`text-center w-full mt-2`}
`

export const ImageWithDelete = tw.div`
    flex items-center space-x-2
`
export const ImageContainer = styled.div`
    ${tw`relative`}
`

export const DeleteButton = styled.button`
    ${tw`absolute top-0 left-0 p-1 m-1  text-black font-bold bg-transparent rounded-full cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out border-none hover:text-[#f5a50b]`}
`
export const ImageContainerWrapper = styled.div`
    ${tw`flex flex-wrap justify-start items-center`}
`
