import React from 'react'
import { CancelButton } from './ButtonCancel-Styles'
import { useHistory } from 'react-router-dom'

interface ButtonCancelProps {
    onClick?: () => void
}

const ButtonCancel = (): JSX.Element => {
    const history = useHistory()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    return (
        <>
            <CancelButton type="button" onClick={() => redirection('/home-wallet')}>
                Cancelar
            </CancelButton>
        </>
    )
}

export default ButtonCancel
