import tw, { styled } from 'twin.macro'

export const ContainerPossessionsConsult = styled.section`
    ${tw`mt-10 py-6 px-4`}
`
export const SpaceStep = styled.div`
    ${tw`w-full h-1.5`}
`
export const TitleForm = styled.p`
    .title {
        ${tw`m-0 text-dominant text-[22px] font-montserrat font-bold`}
    }

    @media (min-width: 1180px) {
        .title {
            ${tw`text-2xl`}
        }
    }
`
export const PublicationProperty = styled.section`
    ${tw`hidden`}
    ${tw`md:block md:my-10 md:p-5 md:min-h-[500px] md:box-shadow[0px 3px 6px #00000029] md:rounded-[10px]`}
`
