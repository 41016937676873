import type { ErrorResponse } from '../../http/typings/api'
import {
    FunctionalityQueryState,
    FeatureEnableFunctionsShape,
} from '../../../domain/models/featureEnableFunctions'
import { FEATURE_ENABLE_FUNCTIONS_GET } from './featureEnableFunctions.types'

const initialFunctionalityQueryState: FunctionalityQueryState = {
    functionalityQuery: [],
    loading: true,
    error: {},
}

const buildErrorState = (
    state: FunctionalityQueryState,
    error: ErrorResponse
): FunctionalityQueryState => ({
    ...state,
    loading: false,
    error: error,
})

export const featureEnableFunctionsReducer = (
    state = initialFunctionalityQueryState,
    action: FeatureEnableFunctionsShape
): FunctionalityQueryState => {
    switch (action.type) {
        case FEATURE_ENABLE_FUNCTIONS_GET.REQUEST:
            return { ...state, loading: true, error: {} }
        case FEATURE_ENABLE_FUNCTIONS_GET.SUCCESS:
            return {
                ...state,
                functionalityQuery: action.payload.data,
                loading: false,
                error: {},
            }
        case FEATURE_ENABLE_FUNCTIONS_GET.FAILURE:
            return buildErrorState(state, action.payload)

        default:
            return state
    }
}
