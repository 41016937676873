import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { LayoutContent } from '../../transverse'
import PossessionsTable from './inc/PossessionsTable/index'
import {
    ContainerPossessionsConsult,
    PublicationProperty,
    SpaceStep,
    TitleForm,
} from './PossessionsConsult-styles'
import { BreadcrumbApp } from '../../components'
import ImageConsult from './inc/ImageConsult/ImageConsult'
import useData from './hooks/useDataList'
import { ContainerTitle2, Title, Title2 } from './inc/TitleModuleConsult/titleModule-styles'
import { useFeatureEnableFunctions } from '../FeatureEnableFunctions/FeatureEnableFunctions'
import { parseStringToBoolean } from '../../utils/misc'

const PossessionsConsult = (): JSX.Element => {
    const history = useHistory()
    const state = useLocation().state as { from: string }
    const dispatch = useDispatch()
    const redirection = (url: string, lastFrom?: string): void => {
        history.push(url, {
            lastFrom,
        })
    }
    const [stateInitial, setStateInitial] = useState(false)
    const { typeProperty, actions, listProperty } = useData(stateInitial)
    console.log('listproperty', listProperty)
    const breadcrumbs = [
        {
            text: 'Inicio',
            active: false,
            onClick: () => {
                redirection('/home-wallet')
            },
        },
        {
            text: 'Consulta de inmueble',
            active: true,
        },
    ]
    const refresh = () => {
        setStateInitial(!stateInitial)
    }

    /* Manejo para apagado de funcionalidades */
    // consulta estado de la funcionalidad en el archivo .env
    const enableM2GetRealStates = process.env.REACT_APP_M2_GET_REAL_STATES_ENABLE as
        | 'false'
        | 'true'
    // Consultar estado de la funcionalidad si es true o false en DB
    const m2GetRealStates = useFeatureEnableFunctions('OnOffFunctionM2GetRealStates')

    // En este useEffect se utiliza para validar, si alguno de los dos parámetros está en false, en caso de que se culpa con esta condición se deberá redirigir al usuario a la página not-found, este caso solo aplica cuando se apaga un componente por completo en este caso Insertar Inmueble
    useEffect(() => {
        if (!m2GetRealStates || !parseStringToBoolean(enableM2GetRealStates)) {
            history.push('/not-found')
        }
    }, [history, m2GetRealStates, enableM2GetRealStates])

    return (
        <LayoutContent>
            <ContainerPossessionsConsult>
                <br />
                <BreadcrumbApp
                    breadcrumbs={breadcrumbs}
                    onBack={() => {
                        redirection('/home-wallet')
                    }}
                />
                <SpaceStep />
                <ImageConsult />
                <ContainerTitle2>
                    <Title>Listado de inmuebles</Title>
                    <Title2 onClick={refresh}>Refrescar</Title2>
                </ContainerTitle2>
                {listProperty && actions && typeProperty && (
                    <PossessionsTable
                        listProperty={listProperty}
                        typeProperty={typeProperty}
                        actions={actions}
                        estado={stateInitial}
                    />
                )}
            </ContainerPossessionsConsult>
        </LayoutContent>
    )
}

export default PossessionsConsult
