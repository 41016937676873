import tw, { styled } from 'twin.macro'
import { CheckInput } from '../../../../components/includes/index'
import { Button } from 'react-bootstrap'

export const TermsAndCondContainer = styled.div`
    color: #744f2e;
    background-color: #fcf8e5;
    padding: 30px;
    max-height: 400px;
    overflow-y: auto;

    /* Estilo del contenido dentro de TermsAndCondContainer */
    & > * {
        margin-bottom: 20px;
    }
`

export const Header = styled.div`
    /* Encabezado */
`

export const TermsBody = styled.div`
    /* Cuerpo */
`

export const Footer = styled.div`
    /* Pie de página */
`
export const ContainerCheck = styled.div`
    ${tw`flex justify-start mt-4`}
`

export const CheckInputTdc = styled(CheckInput)`
    ${tw`block !w-6 !h-6 mr-3 cursor-pointer`}
`

export const CheckTermsCond = styled(CheckInput)`
    ${tw`block !w-6 !h-6 mr-3 cursor-pointer`}
`
export const TermText = styled.p`
    font-family: 'Helvetica', sans-serif;
    color: #53595a;
`
export const StyledCheckbox = styled.input`
    width: 16px;
    height: 16px;
`

export const CancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] color[#000000] rounded-[10px] font-normal font-montserrat`}

    &:hover {
        background-color: #ffffff;
        color: #000;
    }

    margin-left: 20px;
    background-color: #ffffff;
    border-color: #000000 !important;
`

export const AceptButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: var(--sub-dominant-color) !important;
    border: 2px solid transparent !important;
    color: #ffffff;

    &:hover {
        background-color: var(--sub-dominant-color);
        border: 2px solid transparent;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid transparent;
    }
`

AceptButton.displayName = 'AceptButton'
