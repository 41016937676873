import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { featureEnableFunctionsSelector } from '../../../selectors'
import { getFeatureEnableFunctionsAction } from '../../../redux/featureEnableFunctions/featureEnableFunctions.actions'
import { parseStringToBoolean } from '../../utils/misc'

export function useFeatureEnableFunctions(functionName: string): boolean {
    const dispatch = useDispatch()
    const { functionalityQuery } = useSelector(featureEnableFunctionsSelector)

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    useEffect(() => {
        if (tokenSave) {
            dispatch(getFeatureEnableFunctionsAction())
        }
    }, [tokenSave, dispatch])

    const functionality =
        functionalityQuery &&
        (functionalityQuery.find((item) => item.name === functionName)?.value as 'false' | 'true')

    return parseStringToBoolean(functionality)
}
