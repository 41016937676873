import { ContainerTitle, TitleStep } from './titleFromModule-styles'

const TitleFromModule = (): JSX.Element => {
    return (
        <ContainerTitle>
            <TitleStep>
                <span>Publicación Inmueble</span>
            </TitleStep>
        </ContainerTitle>
    )
}

export default TitleFromModule
