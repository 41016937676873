import { useEffect, useState } from 'react'
import { possessionsService } from '../../../../../domain/services/User.service'
import { useSelector } from 'react-redux'

const useData = (stateInitial: boolean) => {
    const [listProperty, setListProperty] = useState(null)
    const [typeProperty, setTypeProperty] = useState(null)
    const [actions, setActions] = useState(null)

    const state = useSelector((stateRef: any) => stateRef)
    const tokenSave = state.auth.token

    useEffect(() => {
        possessionsService
            .useGetSecurity('api/RealEstateExchangeM2/GetRealEstateType', tokenSave)
            .then((response: any) => {
                if (response != undefined) {
                    setTypeProperty(response)
                }
            })

        possessionsService
            .useGetSecurity('api/RealEstateExchangeM2/GetBusinessType', tokenSave)
            .then((response: any) => {
                if (response != undefined) {
                    setActions(response)
                }
            })

        const filterRealStateDto = { RealStateType: 0, RealSatetOffer: 0, Address: '0' }

        possessionsService
            .useGetSecurity(
                `api/RealEstateExchangeM2/GetRealState?realStateType=${filterRealStateDto.RealStateType}&realSatetOffer=${filterRealStateDto.RealSatetOffer}&address=${filterRealStateDto.Address}`,
                tokenSave
            )
            .then((response: any) => {
                if (response != undefined) {
                    setListProperty(response)
                }
            })
    }, [stateInitial])

    return { listProperty, typeProperty, actions }
}

export default useData
