import tw, { styled } from 'twin.macro'
import { Button } from 'react-bootstrap'

export const CancelButton = styled(Button)`
    ${tw`my-2.5 mx-0 w-[200px] h-[50px] rounded-[10px] font-normal font-montserrat`}
    background-color: #ffffff !important;
    color: var(--sub-dominant-color) !important;
    border: 2px solid rgba(0, 0, 0, 0.25) !important;

    &:hover {
        background-color: var(--sub-dominant-color);
        border: 2px solid var(--sub-dominant-color) !important;
    }

    &:focus {
        outline: none;
    }

    &:active {
        border: 2px solid var(--sub-dominant-color);
    }
`

CancelButton.displayName = 'CancelButton'
