import tw, { styled } from 'twin.macro'
import { Form } from 'react-bootstrap'

export const ImgForm = styled.figure`
    ${tw`flex items-center justify-center my-0 mx-auto`}

    .logo-table {
        width: 500px;
        cursor: pointer;
        margin: 30px;
    }
`
