import { SpaceStep } from '../../PossessionsConsult-styles'
import React from 'react'
import { LogoMetroCuadrado } from '../../../../utils/getImages'
import { ImgContent } from './ImageConsult-styles'

const ImageConsult = (): JSX.Element => {
    return (
        <>
            <ImgContent>
                <img src={LogoMetroCuadrado} className="logo-table" alt="LogoMetroCuadrado" />
            </ImgContent>
        </>
    )
}

export default ImageConsult
